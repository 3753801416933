<template>
  <div id="research-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.researchesPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("research-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="name">
            {{ $l.get("research-list.table-name") }}
          </vs-th>
          <vs-th sort-key="is_published">
            {{ $l.get("research-form.is-published-field") }}
          </vs-th>
          <vs-th sort-key="volunteers_percent">
            {{ $l.get("dictionary.samples-progress") }}
          </vs-th>
          <vs-th sort-key="percent_done">
            {{ $l.get("dictionary.progress") }}
          </vs-th>
          <vs-th>{{ $l.get("dictionary.view") }}</vs-th>
          <vs-th>{{ $l.get("dictionary.edit") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.name }}</vs-td>

            <vs-td v-if="tr.isPublished" style="text-align: center">
              <feather-icon icon="CheckIcon" svgClasses="text-success" />
            </vs-td>
            <vs-td v-else style="text-align: center">
              <feather-icon icon="XIcon" svgClasses="text-warning" />
            </vs-td>

            <vs-td>
              <vs-progress :percent="tr.volunteersPercent" color="primary">{{
                tr.volunteersPercent
              }}</vs-progress>
            </vs-td>

            <vs-td v-if="isProcessing(tr)">
              <vs-progress :percent="tr.percentDone" color="primary">{{
                tr.percentDone
              }}</vs-progress>
            </vs-td>
            <vs-td v-else-if="isDone(tr)" style="text-align: center">
              <feather-icon icon="CheckIcon" svgClasses="text-success" />
            </vs-td>
            <vs-td v-else-if="isStopped(tr)" style="text-align: center">
              <feather-icon icon="ClockIcon" svgClasses="text-info" />
            </vs-td>
            <vs-td v-else style="text-align: center">
              <feather-icon icon="AlertIcon" svgClasses="text-warning" />
            </vs-td>

            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="assessment"
                @click="onResearchViewClick(tr)"
              ></vs-button>
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onResearchEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.researchesPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectResearchDetailsInteractor from "@/business/research-list/select-research-details";
import SelectResearchEditInteractor from "@/business/research-list/select-research-edit";
import SelectCreateInteractor from "@/business/research-list/select-create";
import ChangeFilterInteractor from "@/business/research-list/change-filter";
import InitResearchListInteractor from "@/business/research-list/init-research-list";
import ResearchListScreenController from "@/adapters/controllers/screen-research-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "research-list",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectResearchDetails: null,
        selectResearchEdit: null,
        selectCreate: null,
        changeFilter: null,
        initResearchList: null
      },
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null,
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ResearchListScreenController);

    //{ INTERACTORS
    this.interactors.initResearchList = this.$injector.get(
      InitResearchListInteractor
    );
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectResearchEdit = this.$injector.get(
      SelectResearchEditInteractor
    );
    this.interactors.selectResearchDetails = this.$injector.get(
      SelectResearchDetailsInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initResearchList.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    isStopped(research) {
      return research.tasksCount == 0;
    },
    isProcessing(research) {
      return research.percentDone < 100 && research.tasksCount > 0;
    },
    isDone(research) {
      return research.percentDone >= 100;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onResearchViewClick(data) {
      this.interactors.selectResearchDetails.handle(data);
    },
    onResearchEditClick(data) {
      this.interactors.selectResearchEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    }
  }
};
</script>

<style lang="scss"></style>
